import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  flex-direction: column;
  overflow: hidden;
`;
export const LogoContainer = styled.div`
  padding: 10px;
  img {
    max-width: 400px;
  }
`;
export const DataContainer = styled.div``;
export const Title = styled.div`
  font-size: 20px;
  font-family: "Francois One", sans-serif;
  font-weight: 400;
  color: #333e5b;
  letter-spacing: 2;
  text-align: center;
  margin: 5px;
`;
export const Description = styled.div``;
