import React from "react";

import {
  Container,
  LogoContainer,
  DataContainer,
  Title,
  Description,
} from "./styles";
import LogoSvg from "../../img/logo.svg";

export default () => {
  return (
    <Container>
      <LogoContainer>
        <img src={LogoSvg} />
      </LogoContainer>
      <DataContainer>
        <Title>Stay Tuned ! Coming Soon in your City.</Title>
        <Title>To Know more Call/Whatsapp on 9934737102 </Title>
      </DataContainer>
    </Container>
  );
};
